<template>
  <div>
    <v-toolbar outlined>
      <v-toolbar-title>
        {{ $vuetify.lang.t("$vuetify.site['banners']") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="addSlide()">
        <v-icon>mdi-plus</v-icon>
        {{ $vuetify.lang.t("$vuetify.action.add") }}
      </v-btn>
    </v-toolbar>
    <draggable v-if="slider" v-model="slider">
      <transition-group>
        <section
          v-for="slide in slider"
          :key="slide.id"
          class="section"
          :class="slide.enabled ? '' : 'slide-hidden'"
          :id="'slide_' + slide.id"
          :style="{ backgroundImage: 'url(' + slide.img_web + ')' }"
        >
          <div class="sliderBottomWrapper" align="end">
            <div class="sliderBottom">
              <v-icon
                class="mr-5"
                @click="
                  editBannerText(slide)
                "
                >mdi-pencil</v-icon
              >
              <v-icon
                class="mr-5"
                @click="
                  editBannerPopup(slide.id, slide.img_web, slide.img_mobile)
                "
                >mdi-image</v-icon
              >
              <v-icon
                class="mr-5"
                v-if="!slide.enabled"
                @click="slide.enabled = true"
                >mdi-eye-off</v-icon
              >
              <v-icon class="mr-5" v-else @click="slide.enabled = false"
                >mdi-eye</v-icon
              >
              <v-icon class="mr-5" @click="popupDelete(slide.id)"
                >mdi-delete</v-icon
              >
            </div>
          </div>
          <div
            style="padding-left: 5%; width: 60%; margin-top: 0px"
            v-html="slide.content[$i18n.locale]"
          ></div>
        </section>
      </transition-group>
    </draggable>
    <v-btn class="float-right mt-3" @click="save" :loading="saveItemsLoading">
      <v-icon class="mr-3">mdi-content-save</v-icon>
      {{ $vuetify.lang.t("$vuetify.action.save") }}
    </v-btn>
    <ConfirmRemoval :confirmRemoval="confirmRemoval" :item="itemToRemove" />
    <EditButton :editButton="editButton" />
    <UploadBanners :uploadBanners="uploadBanners" />
    <EditBanner :slide="editText" @closeEditTextPopup="closeEditTextPopup"/>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import SliderModel from "@/models/slider";
import EditButton from "@/components/manager/popup/site/EditButton.vue";
import EditBanner from "@/components/manager/popup/site/EditBanner.vue";
import UploadBanners from "@/components/manager/popup/site/UploadBanners.vue";
import ConfirmRemoval from "@/components/manager/popup/site/ConfirmRemoval.vue";
import Api from "@/services/api";
export default {
  components: {
    draggable,
    ConfirmRemoval,
    EditButton,
    UploadBanners,
    EditBanner
  },
  data() {
    return {
      slider: false,
      language: this.$i18n.locale,
      saveItemsLoading: false,
      noImage: "@/assets/img/en.png",
      confirmRemoval: false,
      itemToRemove: false,
      editButton: false,
      editText: false,
      uploadBanners: false,
      slideIdUpload: false,
      slideImgType: false,
    };
  },
  methods: {
    getData() {
      Api.slider("get").then(({ data }) => {
        this.slider = data;
      });
    },
    addSlide() {
      var newItem = JSON.parse(JSON.stringify(SliderModel));
      newItem.id = this.setMaxId(this.slider);
      this.slider.push(newItem);
      setTimeout(() => {
        const el = document.getElementById("slide_" + newItem.id);
        el.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    popupDelete(item) {
      this.itemToRemove = item;
      this.confirmRemoval = true;
    },
    removeItem(slideId) {
      this.slider = this.slider.filter(function (el) {
        return el.id !== slideId;
      });
      this.confirmRemoval = false;
      this.itemToRemove = false;
    },
    editButtonPopup(slideId, language, btnIndex, btnData) {
      this.editButton = [slideId, language, btnIndex, btnData];
    },
    closeButtonPopup(buttonData) {
      var slideIndex = this.getIndexById(this.slider, buttonData[0]);
      this.slider[slideIndex][buttonData[1]]["buttons"][buttonData[2]] =
        buttonData[3];
      this.editButton = false;
    },
    editBannerText(slide) {
      this.editText = slide;
    },
    closeEditTextPopup(slide) {
      if(!slide) {
        this.editText = false;
      }

      var slideIndex = this.getIndexById(this.slider, slide.id);

      var repl = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>';
      slide.content.ka = slide.content.ka.replace(repl, '');
      slide.content.en = slide.content.en.replace(repl, '');
      this.slider[slideIndex] = slide;
      this.editText = false;
    },
    editBannerPopup(slideId, imgWeb, imgMobile) {
      this.uploadBanners = [slideId, imgWeb, imgMobile];
    },
    closeBannersPopup(slideId, mobileImg, webImg) {
      if (mobileImg) {
        this.slideIdUpload = slideId[0];
        this.slideImgType = "img_mobile";
        this.uploadFile(mobileImg);
      }
      if (webImg) {
        this.slideIdUpload = slideId[0];
        this.slideImgType = "img_web";
        this.uploadFile(webImg);
      }
      this.uploadBanners = false;
    },
    uploadFile(file) {
      let formData = new FormData();
      formData.append("file", file);
      Api.media("upload", formData).then(({ data }) => {
        var slideIndex = this.getIndexById(this.slider, this.slideIdUpload);
        this.slider[slideIndex][this.slideImgType] = data.url;
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.manager.imported"));
      });
    },
    getIndexById(items, id) {
      return items
        .map(function (e) {
          return e.id;
        })
        .indexOf(id);
    },
    save() {
      this.saveItemsLoading = true;
      Api.slider("set", this.slider).then(() => {
        this.$toast.ok(this.$vuetify.lang.t("$vuetify.action.update_success"));
        this.saveItemsLoading = false;
      });
    },
    setMaxId(items) {
      let maxId = 1;
      if (items.length == 0) return maxId;
      for (const property in items) {
        if (maxId < items[property].id) {
          maxId = items[property].id;
        }
      }

      return ++maxId;
    },
  },
  created: function () {
    this.getData();
  },
};
</script>
<style scoped>
section {
  border: 1px solid silver;
  display: block;
  width: 100%;
  min-height: 460px;
  margin-top: 10px;
  padding: 10px 20px;
  max-height: 460px;
  background-size: cover;
  background-position: center;
  background-color: silver;
}
.maxwidth {
  width: 100%;
  max-width: 800px;
  color: #fff !important;
}
.offset {
  margin-top: 100px;
  margin-left: 20px;
}
.slider-btn {
  display: inline-block;
  height: inherit;
  padding: 13px 22px 13px 22px;
  font-size: 0.95rem !important;
  border-radius: 4px;
  color: #fff;
  margin-right: 5px;
}
.slider-btn.gs1Orange {
  background: #cd3c0d !important;
}
.slider-btn.gs1Blue {
  background: #002c6c !important;
}
.sliderBottomWrapper {
  position: relative;
  float: right;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 15px;
  margin-top: -10px;
  margin-right: -20px;
}
.sliderBottom button {
  color: #fff;
  font-size: 2rem;
  cursor: pointer;
}
.slide-hidden {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.4;
}
.button-hidden {
  background: #c0c0c0 !important;
  color: #838080;
  margin-right: 5px;
}
.selectedButtonColor {
  border: 5px solid rgb(163, 163, 77);
}
.languageSelector {
  padding: 5px;
  background: #008dbd;
  margin-top: 5px;
  color: #fff;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
.input-text {
  ::v-deep {
    .v-text-field {
      input {
        color: #fff;
      }
    }
  }
}
</style>