<template>
  <v-dialog
    v-if="dialogState"
    v-model="dialogState"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.manager.edit_button") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-row align="start">
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.site.text_' + dialogState[1])"
                v-model="dialogState[3].text"
              ></v-text-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
              <v-text-field
                label="URL"
                v-model="dialogState[3].link"
              ></v-text-field>
            </div>
            <v-row align="start" class="pl-4 pt-3">
              <v-btn
                elevation="2"
                @click="dialogState[3].class = 'slider-btn gs1Orange'"
                class="slider-btn gs1Orange ml-2 mr-2"
                :class="
                  dialogState[3].class == 'slider-btn gs1Orange'
                    ? 'selectedButtonColor'
                    : ''
                "
                tile
              ></v-btn>
              <v-btn
                elevation="2"
                @click="dialogState[3].class = 'slider-btn gs1Blue'"
                class="slider-btn gs1Blue"
                :class="
                  dialogState[3].class == 'slider-btn gs1Blue'
                    ? 'selectedButtonColor'
                    : ''
                "
                tile
              ></v-btn>
            </v-row>
            <v-row align="end">
              <v-switch
                prepend-icon="mdi-eye-off"
                append-icon="mdi-eye"
                v-model="dialogState[3].enabled"
              ></v-switch>
            </v-row>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup()">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="closePopup()">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function () {
    return {
      dialogState: false,
    };
  },
  methods: {
    closePopup() {
      this.$parent.closeButtonPopup(this.dialogState);
    },
  },
  watch: {
    editButton(newVal) {
      this.dialogState = JSON.parse(JSON.stringify(newVal));
    },
  },
  props: ["editButton"],
};
</script>
<style scoped>
.slider-btn.gs1Orange {
  background: #cd3c0d !important;
}
.slider-btn.gs1Blue {
  background: #002c6c !important;
}
.selectedButtonColor {
  border: 5px solid rgb(163, 163, 77);
}
</style>