<template>
  <v-dialog
    v-if="dialogState"
    v-model="dialogState"
    persistent
    max-width="800px"
  >
    <v-card 
    style="min-height:400px">
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.edit") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-5">
        <Editor :model="dialogState.content" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$parent.closeEditTextPopup()">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="$parent.closeEditTextPopup(dialogState)">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "@/components/manager/site/Editor.vue";
export default {
  components: { Editor },
  data: function () {
    return {
      dialogState: false,
    };
  },
  watch: {
    slide(newVal) {
      if (newVal) {
        this.dialogState = JSON.parse(JSON.stringify(newVal));
      } else {
        this.dialogState = false;
      }
    },
  },
  props: ["slide"],
};
</script>