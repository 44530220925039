<template>
  <v-dialog v-if="dialogState" v-model="dialogState" persistent max-width="600px">
    <v-card>
      <v-toolbar color="accent" fixed>
        <v-toolbar-title>
          <v-icon left class="mt-n1">mdi-pencil</v-icon>
          {{ $vuetify.lang.t("$vuetify.manager.edit_banners") }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-5">
        <h2>Mobile (760 x 430)</h2>
        <v-file-input show-size truncate-length="30" v-model="mobile"></v-file-input>
        <h2>Desktop (1740 x 550)</h2>
        <v-file-input show-size truncate-length="30" v-model="web"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closePopup()">
          <v-icon class="mr-3">mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.cancel") }}
        </v-btn>
        <v-btn @click="closePopup()">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.action.update") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      dialogState: false,
      mobile: null,
      web: null,
    };
  },
  methods: {
    closePopup() {
      this.$parent.closeBannersPopup(this.dialogState, this.mobile, this.web);
    },
  },
  watch: {
    uploadBanners(newVal) {
      this.dialogState = JSON.parse(JSON.stringify(newVal));
      this.mobile = null;
      this.web = null;
    },
  },
  props: ["uploadBanners"],
};
</script>
<style scoped>
.slider-btn.gs1Orange {
  background: #cd3c0d !important;
}
.slider-btn.gs1Blue {
  background: #002c6c !important;
}
.selectedButtonColor {
  border: 5px solid rgb(163, 163, 77);
}
</style>